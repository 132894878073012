import React from "react";
import Helmet from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CookieConsent from "react-cookie-consent";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { graphql, useStaticQuery } from "gatsby";

const Layout = ({ title, page, children }) => {
	const location = useLocation();
	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					description
					siteUrl
					title
				}
			}
			wp {
				themeGeneralSettings {
					globalOptions {
						cookieBanner {
							content
							acceptButton
						}
					}
				}
			}
		}
	`);
	const cookieText = data.wp.themeGeneralSettings.globalOptions.cookieBanner.content;
	const cookieButtonText = data.wp.themeGeneralSettings.globalOptions.cookieBanner.acceptButton || "I understand";

	return (
		<>
			<Helmet
				title={`${data.site.siteMetadata.title} | ${title}`}
				defer={false}
				htmlAttributes={{
					lang: "en",
				}}
			>
				<meta
					name="description"
					content={data.site.siteMetadata.description}
				/>
			</Helmet>
			<Header />
			{children}
			{title !== "Contact" && <Footer page={page} />}
			<CookieConsent
				buttonText={cookieButtonText}
				cookieName="gatsby-gdpr-google-tagmanager"
				onAccept={() => {
					initializeAndTrack(location);
				}}
				disableStyles={true}
				buttonClasses="px-4 py-1 border-white border-2 border-opacity-10 rounded-lg mr-4 transition duration-200 hover-hover:hover:bg-white hover-hover:hover:text-purple"
				declineButtonClasses="px-4 py-1 border-white border-2 border-opacity-10 rounded-lg transition duration-200 hover-hover:hover:bg-white hover-hover:hover:text-purple"
				containerClasses="fixed bottom-0 z-50 w-full py-8 px-16 bg-purple bg-opacity-90 backdrop-filter backdrop-blur-md text-white flex flex-col gap-6 md:flex-row justify-between items-center"
				contentClasses="text-lg md:text-xl text-center md:text-left"
			>
				<div dangerouslySetInnerHTML={{ __html: cookieText }}></div>
			</CookieConsent>
		</>
	);
};

export default Layout;
