import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToBottom } from "@fortawesome/pro-light-svg-icons";

const Downloads = ({ downloads }) => {
	return (
		<div className="mb-8 md:mb-12 md:my-16">
			<h2 className="text-3xl lg:text-6xl font-bold">Downloads</h2>
			<div className="my-12 space-y-16">
				{downloads.map((group, i) => {
					return (
						<div key={i}>
							<h3 className="text-teal font-bold text-xl mb-4">{group.label}</h3>
							{group.items.map((item, i) => {
								return (
									<div
										key={i}
										className="grid items-center grid-cols-2 md:grid-cols-4 border-b-2 border-white py-4 md:py-2"
									>
										<div className="font-bold">{item.label}</div>
										<div className="col-span-2 hidden md:block">{item.spec}</div>
										{item.file && (
											<a
												download
												href={item.file.localFile.url}
												className="justify-self-end  hover-hover:hover:text-teal"
											>
												Download
												<FontAwesomeIcon
													icon={faArrowToBottom}
													className="text-teal ml-2"
												/>
											</a>
										)}
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Downloads;
