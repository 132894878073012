import React from "react";
import Logo from "../images/logo.svg";
import HortonsLogo from "../images/hortons-logo.svg";
import Link from "../components/Link";
import Image from "../components/Image";
import PageLinks from "../components/PageLinks";
import { graphql, useStaticQuery } from "gatsby";
import Obfuscate from "react-obfuscate";
import ButtonLink from "../components/ButtonLink";

// export

const Footer = ({ page }) => {
	const data = useStaticQuery(graphql`
		{
			wp {
				themeGeneralSettings {
					globalOptions {
						contactPage {
							... on WpPage {
								id
								contactPage {
									contacts {
										email
										mobile
										name
										image {
											...MediaItem
										}
									}
								}
							}
						}
						mainBrochure {
							mediaItemUrl
						}
						address
					}
				}
			}
		}
	`);
	const contacts = data.wp.themeGeneralSettings.globalOptions.contactPage.contactPage.contacts;
	const address = data.wp.themeGeneralSettings.globalOptions.address;
	const brochure = data.wp.themeGeneralSettings.globalOptions.mainBrochure;
	const directions = data.wp.themeGeneralSettings.globalOptions.directionsLink;

	return (
		<div className="mt-auto">
			{page && page.pageLinks.pageLinks && <PageLinks links={page.pageLinks} />}
			<div className={`container mx-auto mt-auto border-t-2 border-white border-opacity-10`}>
				{brochure && (
					<ButtonLink
						text={"Download Brochure"}
						url={brochure.mediaItemUrl}
						classes="py-6 px-6 md:px-12"
					/>
				)}
			</div>
			<footer className="lg:container mx-auto grid md:grid-cols-3 border-t-2 border-white border-opacity-10">
				<div className="col-span-1 py-16 px-6 md:p-8">
					<img
						className="block w-48"
						src={Logo}
						alt={`Logo`}
						width={300}
						height={87}
					/>
					<address
						className="font-normal not-italic my-8"
						dangerouslySetInnerHTML={{ __html: address }}
					></address>
					{directions && (
						<Link
							text="Get directions"
							url={directions}
							target="_blank"
							classes={`hover-hover:hover:bg-transparent`}
						/>
					)}
				</div>
				<div className="md:col-span-2 py-16 px-6 md:p-8 border-t-2 md:border-t-0 md:border-l-2 border-white border-opacity-10">
					<h3 className="text-teal font-bold text-xl mb-8">Contact</h3>
					<div className="flex flex-col gap-[30px]">
						<div className="flex flex-wrap gap-8 md:gap-24">
							{contacts.map((contact, index) => {
								return (
									<div key={index}>
										<div className="w-32 md:w-40 h-32">
											<Image image={contact.image} />
										</div>
										<h4 className="font-bold">{contact.name}</h4>
										<div className="font-bold">
											<span className="text-teal">m. </span>
											<Obfuscate tel={contact.mobile}>{contact.mobile}</Obfuscate>
										</div>

										<Obfuscate
											email={contact.email}
											className="text-teal underline hover-hover:hover:text-white"
										>
											Email
										</Obfuscate>
									</div>
								);
							})}
						</div>
						<a
							href="https://www.hortons.co.uk/"
							target="_blank"
							rel="nofollow"
							className="mt-16 md:mt-0 md:ml-auto md:self-start transform transition-all hover:opacity-75 hover:scale-95"
						>
							<img
								className="block w-32"
								src={HortonsLogo}
								alt="Hortons Logo"
								width="277"
								height="150"
							/>
						</a>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Footer;
